import { useEffect, useState } from "react";
import { RWebShare } from "react-web-share";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ApplyInstantComponent from "../Components/applyInstantComponent";
import ReviewLoan from "../Components/reviewLoan";
import { validateContact, validateName } from "../Common/validations";
import { getLoan, updateRef } from "../api/loan";
import { getLocalStorage } from "../Common/cookie";
import pdf from "../Assets/img/pdf.png";
import Modal from "react-bootstrap/Modal";
import logo from "../Assets/img/logoLight.png";

function ApplyNowInstant10(props) {
  const { socket, logindata, setLogindata } = props;
  const query = new URLSearchParams(useLocation().search);
  const id = query.get("id");
  const navigate = useNavigate();

  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [seconds, setSeconds] = useState(null);
  const [code, setcode] = useState(new Array(6).fill(""));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const asyncFn = async () => {
      const checkCookie = getLocalStorage("rv-user");
      if (checkCookie) {
        const response = await getLoan({
          loan_id: id,
        });
        if (response?.status === 200) {
          setData(response.data);
          const timer = setTimeout(() => {
            downloadFiles(response.data);
          }, 1000);

          return () => clearTimeout(timer);
        }
      }
    };
    asyncFn();
  }, [id, logindata]);

  const downloadFiles = async (response) => {
    await downloadPdf(
      `${process.env.REACT_APP_API}${response.agreement}`,
      response.agreement.split("/").pop()
    );
    await downloadPdf(
      `${process.env.REACT_APP_API}${response.sanction_letter}`,
      response.sanction_letter.split("/").pop()
    );
    await downloadPdf(
      `${process.env.REACT_APP_API}${response.kfs}`,
      response.kfs.split("/").pop()
    );
  };

  const downloadPdf = async (path, name) => {
    try {
      const response = await fetch(path, {
        headers: {
          "Content-Type": "application/pdf",
        },
      });
      if (!response.ok) {
        console.error("Network response was not ok");
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", name); // Specify the file name
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Error downloading the PDF", error);
    }
  };

  async function saveData(e) {
    e.preventDefault();
    // check checkbox
    handleShow();
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);
    if (seconds === 0) {
      clearInterval(intervalId);
    }
    return () => clearInterval(intervalId);
  }, [seconds]);

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false;
    setcode([...code.map((d, indx) => (indx === index ? element.value : d))]);
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const verifyLoan = () => {
    navigate(`/apply-now/Instant/thanks?id=${id}`);
  };

  return (
    <>
      <ApplyInstantComponent>
        <h3>Agreement Form</h3>
        <p>
          Please go through the Loan agreement and accept the terms and
          conditions to proceed.
        </p>
        <div class="contact-form pb-2">
          <div class="form-group mt-30">
            <div className="card p-3">
              <table className="mb-0">
                <tr>
                  <td>Loan Amount</td>
                  <td style={{ textAlign: "right" }}>₹{data.offered_amount}</td>
                </tr>
                <tr>
                  <td>EMI Amount</td>
                  <td style={{ textAlign: "right" }}>
                    ₹{data.emi_amount ? data.emi_amount : "0"}
                  </td>
                </tr>
                <tr>
                  <td>Processing Fees</td>
                  <td style={{ textAlign: "right" }}>
                    ₹{data.processing_fee ? data.processing_fee : "0"}
                  </td>
                </tr>
                <tr>
                  <td>Loan Tenure</td>
                  <td style={{ textAlign: "right" }}>
                    {data.offered_tenure} {data.offered_tenure_in}
                  </td>
                </tr>
                <tr>
                  <td>Interest Rate</td>
                  <td style={{ textAlign: "right" }}>
                    {data.offered_interest ? data.offered_interest : "0"}%
                  </td>
                </tr>
              </table>
            </div>
            <div
              style={{
                width: "250px",
                height: "50px",
                background: "#f9f9f9",
                overflow: "hidden",
                borderRadius: "8px",
                border: "1px solid #ccc",
                marginTop: "15px",
              }}
            >
              {data.kfs && (
                <Link
                  rel="noopener noreferrer"
                  to={`${process.env.REACT_APP_API}${data.kfs}`}
                  target="_blank"
                >
                  <div className="d-flex p-1">
                    <div>
                      <img src={pdf} alt="pdf" height="40" width="40" />
                    </div>
                    <div
                      className="d-flex align-items-center"
                      style={{ fontSize: "12px" }}
                    >
                      {data.kfs.split("/").pop()}
                    </div>
                  </div>
                </Link>
              )}
            </div>
            <div
              style={{
                width: "250px",
                height: "50px",
                background: "#f9f9f9",
                overflow: "hidden",
                borderRadius: "8px",
                border: "1px solid #ccc",
                marginTop: "15px",
              }}
            >
              {data.agreement && (
                <Link
                  rel="noopener noreferrer"
                  to={`${process.env.REACT_APP_API}${data.agreement}`}
                  target="_blank"
                >
                  <div className="d-flex p-1">
                    <div>
                      <img src={pdf} alt="pdf" height="40" width="40" />
                    </div>
                    <div
                      className="d-flex align-items-center"
                      style={{ fontSize: "12px" }}
                    >
                      {data.agreement.split("/").pop()}
                    </div>
                  </div>
                </Link>
              )}
            </div>
            <div
              style={{
                width: "250px",
                height: "50px",
                background: "#f9f9f9",
                overflow: "hidden",
                borderRadius: "8px",
                border: "1px solid #ccc",
                marginTop: "15px",
              }}
            >
              {data.sanction_letter && (
                <Link
                  rel="noopener noreferrer"
                  to={`${process.env.REACT_APP_API}${data.sanction_letter}`}
                  target="_blank"
                >
                  <div className="d-flex p-1">
                    <div>
                      <img src={pdf} alt="pdf" height="40" width="40" />
                    </div>
                    <div
                      className="d-flex align-items-center"
                      style={{ fontSize: "12px" }}
                    >
                      {data.sanction_letter.split("/").pop()}
                    </div>
                  </div>
                </Link>
              )}
            </div>
            <button
              type="submit"
              onClick={saveData}
              class="default-btn disabled mt-3"
              style={{ pointerEvents: "all", cursor: "pointer" }}
            >
              Send OTP <span></span>
            </button>
          </div>
        </div>
      </ApplyInstantComponent>

      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <div className="d-flex justify-content-center">
            <div>
              <img src={logo} alt="" width="100" />
            </div>
          </div>
          <h6 className="mt-2 mb-0">
            <center>
              OTP is sent to ******3301 number for verifying loan request.
            </center>
          </h6>
          <div className="section d-flex justify-content-center">
            <form style={{ width: "260px" }}>
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <div className="d-flex justify-content-between">
                      <label className="label-login">OTP</label>
                      <label
                        className="label-login"
                        style={{ fontWeight: 400 }}
                      >
                        {seconds === 0
                          ? "OTP Expired"
                          : seconds >= 60
                          ? `${Math.floor(seconds / 60)}:${(seconds % 60)
                              .toString()
                              .padStart(2, "0")}`
                          : `0:${seconds}`}
                      </label>
                    </div>
                    <div className="otp-container">
                      {code.map((data, index) => {
                        return (
                          <input
                            type="text"
                            className="otp-input"
                            name="otp"
                            maxLength={1}
                            key={index}
                            value={data}
                            onFocus={(e) => e.target.select}
                            autoFocus={index === 0} // add this line
                            onChange={(e) => handleChange(e.target, index)}
                          />
                        );
                      })}
                    </div>
                    {errors.otp && (
                      <div class="help-block with-errors">{errors.otp}</div>
                    )}
                  </div>
                </div>
                <div class="col-12">
                  <center>
                    <button
                      onClick={verifyLoan}
                      className="default-btn btn-sm mt-3"
                    >
                      Verify
                    </button>
                  </center>
                </div>
                <div class="col-12 mt-3 text-center">
                  Haven't received OTP?{" "}
                  {/* <button onClick={sendOTP} className="resend-btn">
                      Resend OTP
                    </button> */}
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default ApplyNowInstant10;
