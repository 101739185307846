import { useEffect, useState } from "react";
import { RWebShare } from "react-web-share";
import { useLocation, useNavigate } from "react-router-dom";
import ApplyInstantComponent from "../Components/applyInstantComponent";
import ReviewLoan from "../Components/reviewLoan";
import { validateContact, validateName } from "../Common/validations";
import { getLoan, updateRef } from "../api/loan";
import { getLocalStorage } from "../Common/cookie";
import loader from "../Assets/img/LoanIcon/loader.gif";

function ApplyNowInstant8(props) {
  const { socket, logindata, setLogindata } = props;
  const query = new URLSearchParams(useLocation().search);
  const id = query.get("id");
  const navigate = useNavigate();

  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const asyncFn = async () => {
      const checkCookie = getLocalStorage("rv-user");
      if (checkCookie) {
        const response = await getLoan({
          loan_id: id,
        });
        if (response?.status === 200) {
          setData(response.data);
        }
      }
    };
    asyncFn();
  }, [logindata, id]);

  function onChangeData(e) {
    if (
      (e.target.name === "ref1_phone" || e.target.name === "ref2_phone") &&
      isNaN(e.target.value)
    ) {
      return false;
    }
    setData((data) => ({
      ...data,
      [e.target.name]: e.target.value,
    }));
  }

  function validateRef1Name(str) {
    setErrors((errors) => ({
      ...errors,
      ref1_name: validateName(str).error,
    }));
  }
  function validateRef2Name(str) {
    setErrors((errors) => ({
      ...errors,
      ref1_name: validateName(str).error,
    }));
  }
  function validateRef1Phone(str) {
    setErrors((errors) => ({
      ...errors,
      ref1_phone: validateContact(str).error,
    }));
  }
  function validateRef2Phone(str) {
    setErrors((errors) => ({
      ...errors,
      ref2_phone: validateContact(str).error,
    }));
  }

  function validateForm() {
    validateRef1Name(data.ref1_name);
    validateRef2Name(data.ref2_name);
    validateRef1Phone(data.ref1_phone);
    validateRef2Phone(data.ref2_phone);
    let valid =
      validateName(data.ref1_name).valid &&
      validateName(data.ref2_name).valid &&
      validateContact(data.ref1_phone).valid &&
      validateContact(data.ref2_phone).valid;
    return valid;
  }

  async function saveData(e) {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      const response = await updateRef({
        token: data.accessToken || getLocalStorage("rv-user")?.accessToken,
        data: {
          id: id,
          ref1_name: data.ref1_name,
          ref1_phone: data.ref1_phone,
          ref2_name: data.ref2_name,
          ref2_phone: data.ref2_phone,
        },
      });
      if (response?.status === 200) {
        setLoading(false);
        setData(response.data);
        navigate(`/apply-now/Instant/docs?id=${id}`);
      }
    }
  }

  return (
    <>
      <ApplyInstantComponent>
        <h3>Reference Details</h3>
        <p>Help us with two references</p>
        <div class="contact-form pb-2">
          <div class="form-group mt-30">
            <h5>Reference 1</h5>
            <div class="form-group">
              <label className="label-login">Name: </label>
              <input
                type="text"
                name="ref1_name"
                value={data.ref1_name}
                class="form-control w-100"
                onBlur={() => validateRef1Name(data.ref1_name)}
                onChange={(e) => {
                  onChangeData(e);
                }}
              />
              {errors.ref1_name && (
                <div class="help-block with-errors">{errors.ref1_name}</div>
              )}
            </div>
            <div class="form-group">
              <label className="label-login">Mobile No.</label>
              <input
                type="text"
                name="ref1_phone"
                class="form-control"
                value={data.ref1_phone}
                onBlur={() => validateRef1Phone(data.ref1_phone)}
                onChange={(e) => onChangeData(e)}
              />
              {errors.ref1_phone && (
                <div class="help-block with-errors">{errors.ref1_phone}</div>
              )}
            </div>
            <h5 className="mt-30">Reference 2</h5>
            <div class="form-group">
              <label className="label-login">Name: </label>
              <input
                type="text"
                name="ref2_name"
                value={data.ref2_name}
                class="form-control w-100"
                onBlur={() => validateRef2Name(data.ref2_name)}
                onChange={(e) => {
                  onChangeData(e);
                }}
              />
              {errors.ref2_name && (
                <div class="help-block with-errors">{errors.ref2_name}</div>
              )}
            </div>
            <div class="form-group">
              <label className="label-login">Mobile No.</label>
              <input
                type="text"
                name="ref2_phone"
                class="form-control"
                value={data.ref2_phone}
                onBlur={() => validateRef2Phone(data.ref2_phone)}
                onChange={(e) => onChangeData(e)}
              />
              {errors.ref2_phone && (
                <div class="help-block with-errors">{errors.ref2_phone}</div>
              )}
            </div>
            <button
              type="submit"
              onClick={saveData}
              class="default-btn disabled"
              disabled={loading}
              style={{ pointerEvents: "all", cursor: "pointer" }}
            >
              {loading ? "Loading..." : "Proceed"} <span></span>
            </button>
          </div>
        </div>
      </ApplyInstantComponent>
    </>
  );
}
export default ApplyNowInstant8;
