import BreadCrumbs from "../Components/breadcrumbs";
import img1 from "../Assets/img/Loan/cc2.png";
import img2 from "../Assets/img/Loan/cc1.png";
import ApplyNowForm from "../Components/applyNowForm";
import { useEffect } from "react";

function ApplyCCIdfc(props) {
  const { logindata, setLogindata } = props;
  const paths = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Credit Card",
    },
    {
      name: "IDFC Bank Credit Card",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <BreadCrumbs paths={paths} />
      <div class="container">
        <div class="section-60">
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-12 d-none d-lg-block">
              <div class="about-image">
                <img src={img1} alt="image" />
                <img src={img2} alt="image" />
              </div>
            </div>

            <div class="col-lg-6 col-md-12">
              <div className="py-3">
                <div class="section-title text-left">
                  Get IDFC Bank Credit Card
                </div>
                <ApplyNowForm
                  logindata={logindata}
                  type="IDFC"
                  applyFor="CC"
                  link="https://www.idfcfirstbank.com/credit-card/ntb-diy/apply?utm_source=Partner&utm_medium=STAR_POWERZ&utm_campaign=NTB_STAR_POWERZ%20"
                />
              </div>
            </div>
          </div>
        </div>
        {/* <ApplyInsuranceProducts type="Bike" /> */}
      </div>
    </>
  );
}
export default ApplyCCIdfc;
