import { useEffect, useState } from "react";
import { RWebShare } from "react-web-share";
import { useLocation, useNavigate } from "react-router-dom";
import ApplyInstantComponent from "../Components/applyInstantComponent";
import ProfileProfessional from "../Components/profileProfessional";
import RequestLoan from "../Components/requestLoan";
import ProfileIdentity from "../Components/profileIdentity";
import ProfileAddress from "../Components/profileAddress";

function ApplyNowInstant5(props) {
  const { socket, logindata, setLogindata } = props;
  const query = new URLSearchParams(useLocation().search);
  const id = query.get("id");
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function handleAddressDetails(status) {
    if (status === "success") {
      navigate(`/apply-now/Instant/bank?id=${id}`);
    }
  }

  return (
    <>
      <ApplyInstantComponent>
        <h3>Confirm Your Address</h3>
        <div class="contact-form pb-2">
          <div class="form-group mt-30">
            <ProfileAddress
              msg="Proceed"
              onSubmit={handleAddressDetails}
              logindata={logindata}
              socket={socket}
              loanId={id}
            />
          </div>
        </div>
      </ApplyInstantComponent>
    </>
  );
}
export default ApplyNowInstant5;
