import BreadCrumbs from "../Components/breadcrumbs";
import img1 from "../Assets/img/Loan/cc2.png";
import img2 from "../Assets/img/Loan/cc1.png";
import ApplyNowForm from "../Components/applyNowForm";
import { useEffect } from "react";

function ApplyCCSC() {
  const paths = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Credit Card",
    },
    {
      name: "Standard Chartered Bank Credit Card",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <BreadCrumbs paths={paths} />
      <div class="container">
        <div class="section-60">
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-12 d-none d-lg-block">
              <div class="about-image">
                <img src={img1} alt="image" />
                <img src={img2} alt="image" />
              </div>
            </div>

            <div class="col-lg-6 col-md-12">
              <div className="py-3">
                <div class="section-title text-left">
                  Get Standard Chartered Bank Credit Card
                </div>
                <ApplyNowForm
                  type="StandardCharted"
                  applyFor="CC"
                  link="https://apply.standardchartered.co.in/credit-card/diy-journey-tps/?dt=starpowerz_hyderabad"
                  datalink="/https:\/\/apply\.standardchartered\.co\.in\/credit-card\/instant-approval\/\?u=[^&]+/"
                  successId="body > div.uk-container.uk-container-small.uk-margin-large-top.uk-margin-large-bottom > div > div > div > p > span > span"
                />
              </div>
            </div>
          </div>
        </div>
        {/* <ApplyInsuranceProducts type="Bike" /> */}
      </div>
    </>
  );
}
export default ApplyCCSC;
