import { useEffect, useState } from "react";
import { RWebShare } from "react-web-share";
import { useNavigate } from "react-router-dom";
import ApplyInstantComponent from "../Components/applyInstantComponent";
import ProfileProfessional from "../Components/profileProfessional";
import RequestLoan from "../Components/requestLoan";

function ApplyNowInstant3(props) {
  const { socket, logindata, setLogindata } = props;
  const [id, setId] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function handleRequestLoan(status) {}

  return (
    <>
      <ApplyInstantComponent>
        {/* <h3>Request for your Personal Loan</h3> */}
        <div class="contact-form pb-2">
          <div class="form-group mt-30">
            <RequestLoan
              msg="Proceed"
              onSubmit={handleRequestLoan}
              socket={socket}
              setId={(e) => setId(e)}
            />
          </div>
        </div>
      </ApplyInstantComponent>
    </>
  );
}
export default ApplyNowInstant3;
