import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import testimonials1 from "../Assets/img/Testimonials/1.jpg";
function Testimonials() {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 992 },
      items: 3,
      slidesToSlide: 1,
      paritialVisibilityGutter: 60,
    },
    tablet: {
      breakpoint: { max: 992, min: 678 },
      items: 2,
      slidesToSlide: 1,
      partialVisibilityGutter: 50,
    },
    mobile: {
      breakpoint: { max: 678, min: 0 },
      items: 1,
      slidesToSlide: 1,
      partialVisibilityGutter: 0,
    },
  };
  const review = [
    {
      review:
        "Sed ut perspiciatis unde omnis iste natus error sit volupt accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae illo.",
      name: "James Anderson",
      position: "Manager",
      image: testimonials1,
    },
    {
      review:
        "Sed ut perspiciatis unde omnis iste natus error sit volupt accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae illo.",
      name: "Nency",
      position: "Manager",
      image: testimonials1,
    },
    {
      review:
        "Sed ut perspiciatis unde omnis iste natus error sit volupt accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae illo.",
      name: "Abhi",
      position: "Manager",
      image: testimonials1,
    },
  ];
  const CustomRight = ({ onClick }) => <></>;
  const CustomLeft = ({ onClick }) => <></>;
  const CustomDot = ({ onMove, index, onClick, active }) => <></>;
  return (
    <div className="container">
      <div className="section-60">
        <div class="section-title">WHAT CLIENT SAYS</div>
        <Carousel
          responsive={responsive}
          itemClass="carousel-item-padding"
          ssr={true}
          infinite={true}
          showDots={true}
          autoPlay={true}
          autoPlaySpeed={3000}
          keyBoardControl={true}
          customTransition="all .5"
          transitionDuration={500}
          // slidesToSlide={1}
          customRightArrow={<CustomRight />}
          customLeftArrow={<CustomLeft />}
          customDot={<CustomDot />}
        >
          {review.map((r) => {
            return (
              <div class="owl-item p-2">
                <div class="single-feedback-item">
                  <div class="feedback-desc">
                    <p className="text-justify">{r.review}</p>
                  </div>

                  <div class="client-info">
                    <img src={r.image} alt="image" />
                    <h3>{r.name}</h3>
                    <span>{r.position}</span>
                  </div>
                </div>
              </div>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
}
export default Testimonials;
