import process1 from "../Assets/img/Process/eligibility.png";
import process2 from "../Assets/img/Process/upload.png";
import process3 from "../Assets/img/Process/approve.png";
function Process() {
  const step = [
    {
      image: process1,
      title: "Check Eligibility",
      desc: "Determine your loan eligibility instantly with our streamlined eligibility check process, ensuring you can access the funds you need with ease.",
    },
    {
      image: process2,
      title: "Submit Documents",
      desc: "Simply upload your required documents securely through our online platform to expedite the loan approval process swiftly.",
    },
    {
      image: process3,
      title: "Approval in Principle",
      desc: "Receive an approval in principle for your loan application promptly, providing you with initial confirmation of eligibility before finalizing the process.",
    },
  ];
  return (
    <div className="section-60 bg-f8f8f8">
      <div className="container">
        <div class="section-title">Fast & Easy Application Process</div>
        <div className="sub-title text-center pb-3">
          Experience a lightning-fast and effortlessly streamlined loan
          application process, ensuring quick access to the funds you need
          without any hassle. With just a few simple steps, you can apply for a
          loan swiftly and securely, making financial assistance more accessible
          than ever before.
        </div>
        <div class="row gx-2 justify-content-center">
          {step.map((s) => {
            return (
              <div class="col-lg-4 col-md-6 col-sm-6 mt-30">
                <div class="single-about-box">
                  <div class="icon p-2">
                    <img src={s.image} alt="step" width="75" />
                  </div>
                  <h3>{s.title}</h3>
                </div>
                {/* <p>{s.desc}</p> */}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
export default Process;
