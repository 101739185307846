import About from "../Components/about";
import BreadCrumbs from "../Components/breadcrumbs";
import CCOptions from "../Components/ccOptions";
import InsuranceOptions from "../Components/insuranceOptions";
import LoanOptions from "../Components/loanOptions";
import Mission from "../Components/mission";
import Partner from "../Components/partner";
import Testimonials from "../Components/testimonials";
import WhyChooseUs from "../Components/whyChooseUs";
import { useEffect } from "react";

function AboutPage() {
  const paths = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "About Us",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <BreadCrumbs paths={paths} />
      <div className="bg-f8f8f8">
        <About nolink />
        <Mission />
      </div>
      <WhyChooseUs />
      <LoanOptions />
      <CCOptions />
      <InsuranceOptions />
      <Testimonials />
      <Partner />
    </>
  );
}
export default AboutPage;
