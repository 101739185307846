import { useEffect, useState } from "react";
import BreadCrumbs from "../Components/breadcrumbs";
import { getCustomerIncompleteLoans, getCustomerLoans } from "../api/customer";
import { getLocalStorage } from "../Common/cookie";
import { Link, useNavigate } from "react-router-dom";
import instantIcon from "../Assets/img/Loan/instantIcon.png";
import cartIcon from "../Assets/img/Loan/carIcon.png";
import hometIcon from "../Assets/img/Loan/homeIcon.png";
import eduIcon from "../Assets/img/Loan/eduIcon.png";
import machineIcon from "../Assets/img/Loan/machineIcon.png";
import businessIcon from "../Assets/img/Loan/businessIcon.png";
import morgageIcon from "../Assets/img/Loan/morgage.png";
import proffessionalIcon from "../Assets/img/Loan/professional.png";

function Loans() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  const paths = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "My Loans",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const asyncFn = async () => {
      const checkCookie = getLocalStorage("rv-user");
      if (checkCookie) {
        const response = await getCustomerLoans({
          customer_id: checkCookie.id,
          token: checkCookie.accessToken,
        });
        if (response?.status === 200) {
          setData(response.data);
          await getImg(response.data);
          await checkIncompleteLoans();
        }
      } else {
        navigate("/");
      }
    };
    asyncFn();
  }, []);

  async function checkIncompleteLoans() {
    const checkCookie = getLocalStorage("rv-user");
    const response = await getCustomerIncompleteLoans({
      customer_id: checkCookie.id,
      token: checkCookie.accessToken,
    });
    if (response?.status === 200) {
      if (response.data?.id) {
        const nextLink = await checkNext(
          response.data.id,
          response.data.status
        );
        setData((prevData) => {
          return prevData.map((item) => {
            if (item.id === response.data.id) {
              return { ...item, nextLink: nextLink };
            }
            return item;
          });
        });
      }
    }
  }

  const checkNext = (id, status) => {
    if (status === "CounterOffer") {
      return `/apply-now/Instant/offered?id=${id}`;
    } else if (status === "ResponseByClient") {
      return `/apply-now/Instant/kyc?id=${id}`;
    } else if (status === "CustomerKYC") {
      return `/apply-now/Instant/address?id=${id}`;
    } else if (status === "CustomerAddress") {
      return `/apply-now/Instant/bank?id=${id}`;
    } else if (status === "CustomerBank") {
      return `/apply-now/Instant/review?id=${id}`;
    } else if (status === "LoanReference") {
      return `/apply-now/Instant/docs?id=${id}`;
    }
  };

  function getImg(res_data) {
    let data = res_data;
    if (data.length > 0) {
      data.map((d) => {
        if (d.loan_type === "Instant") {
          d.img = instantIcon;
        } else if (d.loan_type === "Business") {
          d.img = businessIcon;
        } else if (d.loan_type === "Car") {
          d.img = cartIcon;
        } else if (d.loan_type === "Education") {
          d.img = eduIcon;
        } else if (d.loan_type === "Home") {
          d.img = hometIcon;
        } else if (d.loan_type === "AgainstProperty") {
          d.img = morgageIcon;
        } else if (d.loan_type === "Machinary") {
          d.img = machineIcon;
        } else if (d.loan_type === "Professional") {
          d.img = proffessionalIcon;
        }
      });
      setData(data);
    }
  }

  return (
    <>
      <BreadCrumbs paths={paths} />
      <div className="container section-60">
        <div class="row gx-2 justify-content-center">
          {data.length > 0 ? (
            data.map((l) => {
              return (
                <div class="col-md-6 col-12 mt-30">
                  <div className="card single-card p-3 h-100 ">
                    <div className="d-flex justify-content-between flex-column h-100 loan-type">
                      <div>
                        <div className="d-flex">
                          <div className="w-100">
                            <div>
                              <b style={{ fontSize: "16px" }}>
                                {l.loan_type} Loan
                              </b>
                              <p className="mt-2 mr-2">
                                Loan for <b>₹{l.offered_amount}</b> with{" "}
                                <b>{l.offered_interest}%</b> of interest
                                <br />
                                {l.emi_amount && (
                                  <>
                                    <b>EMI:</b> ₹{l.emi_amount}
                                    <br />
                                  </>
                                )}
                                Applied on{" "}
                                {new Date(l.created_at).toLocaleDateString(
                                  "en-GB"
                                )}
                                {l.nextLink && (
                                  <div className="mt-3 ">
                                    <Link
                                      to={l.nextLink}
                                      style={{ color: "#002d5b" }}
                                    >
                                      Exciting Offers are waiting for you. Let's
                                      countinue from where you left.
                                      <b> Countinue</b>
                                    </Link>
                                  </div>
                                )}
                              </p>
                            </div>
                          </div>
                          <div
                            className="bg-f8f8f8 p-2"
                            style={{
                              borderRadius: "15px",
                              height: "60px",
                              width: "60px",
                            }}
                          >
                            <img
                              src={l.img}
                              alt="icon"
                              height="40"
                              width="40"
                            />
                          </div>
                        </div>
                      </div>
                      {/* <div className="mt-3"> */}
                      {/* <Link to={l.applyNow}>
                    <b>Apply Now</b>
                  </Link> */}
                      {/* </div> */}
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <>
              <h5>You have not applied for loan yet.</h5>
            </>
          )}
        </div>
      </div>
    </>
  );
}
export default Loans;
// /uploads/PANCard/1715191903759-cust-PROP PAN_compressed.pdf
