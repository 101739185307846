import { useEffect, useState } from "react";
import {
  validateContact,
  validateEmail,
  validateName,
  validatePan,
  validateSalary,
  validateSelect,
  validatePin,
  validateText,
  validateAadhar,
} from "../Common/validations";

import { toast } from "react-toastify";
import { getLocalStorage } from "../Common/cookie";
import { applyLoan } from "../api/loan";
import { getCustomer } from "../api/customer";
import { getLocation } from "../api/location";
import { applyInsurance } from "../api/insurance";
import { applyCC } from "../api/cc";

function ApplyNowForm(props) {
  const { type, applyFor, link, datalink, successId, logindata, differentPage } = props;
  const [data, setData] = useState({});
  const [profileDB, setProfileDB] = useState("");
  const [selectedProfileFile, setSelectedProfileFile] = useState({
    preview: "",
    raw: "",
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const asyncFn = async () => {
      const checkCookie = getLocalStorage("rv-user");
      if (checkCookie) {
        const response = await getCustomer({
          customer_id: checkCookie.id,
          token: checkCookie.accessToken,
        });
        if (response?.status === 200) {
          setData(response.data);
          setProfileDB(response.data?.profile);
        }
      }
    };
    asyncFn();
  }, [logindata]);

  useEffect(() => {
    if (data.pincode && data.pincode.length === 6) {
      const asyncFn = async () => {
        await getLocData(data.pincode);
      };
      asyncFn();
    }
  }, [data.pincode]);

  const getLocData = async (pincode) => {
    const location = await getLocation({
      pincode: pincode,
    });

    if (location[0]?.Status === "Success") {
      const lData = location[0].PostOffice[0];
      setData((data) => ({
        ...data,
        city: lData.District,
        state: lData.State,
        country: lData.Country,
      }));
    } else {
      setErrors((errors) => ({
        ...errors,
        pincode: "Please enter valid Pin Code",
      }));
    }
  };

  function onChangeData(e) {
    if (
      (e.target.name === "phone" ||
        e.target.name === "pincode" ||
        e.target.name === "income_per_month") &&
      isNaN(e.target.value)
    ) {
      return false;
    }
    setData((data) => ({
      ...data,
      [e.target.name]: e.target.value,
    }));
  }

  const handleProfileUpload = (event) => {
    const file = event.target.files[0];
    setSelectedProfileFile({
      preview: URL.createObjectURL(file),
      raw: file,
    });
  };

  function validateEmailAddr(str) {
    setErrors((errors) => ({
      ...errors,
      email: validateEmail(str).error,
    }));
  }
  function validatePhoneNo(str) {
    setErrors((errors) => ({
      ...errors,
      phone: validateContact(str).error,
    }));
  }
  function validateFName(str) {
    setErrors((errors) => ({
      ...errors,
      name: validateName(str).error,
    }));
  }
  function validatePincode(str) {
    setErrors((errors) => ({
      ...errors,
      pincode: validatePin(str).error,
    }));
  }
  function validatePanNo(str) {
    setErrors((errors) => ({
      ...errors,
      pan_no: validatePan(str).error,
    }));
  }
  function validateAadharNo(str) {
    setErrors((errors) => ({
      ...errors,
      aadhar_no: validateAadhar(str).error,
    }));
  }
  function validateOccupationType(str) {
    setErrors((errors) => ({
      ...errors,
      occupation_type: validateSelect(str).error,
    }));
  }
  function validateIncome(str) {
    setErrors((errors) => ({
      ...errors,
      income_per_month: validateSalary(str).error,
    }));
  }
  function validateCompanyName(str) {
    setErrors((errors) => ({
      ...errors,
      company_name: validateText(str).error,
    }));
  }
  function validateNominee(str) {
    setErrors((errors) => ({
      ...errors,
      nominee: validateText(str).error,
    }));
  }
  function validateRelation(str) {
    setErrors((errors) => ({
      ...errors,
      relation: validateText(str).error,
    }));
  }
  function validateProfileFile() {
    setErrors((errors) => ({
      ...errors,
      profile: validateText(
        selectedProfileFile?.preview ? selectedProfileFile?.preview : profileDB
      ).error,
    }));
  }

  function validateFormCC() {
    validatePanNo(data.pan_no);
    validateOccupationType(data.occupation_type);
    validateIncome(data.income_per_month);
    validateCompanyName(data.company_name);
    let valid =
      validateForm() &&
      validatePan(data.pan_no).valid &&
      validateSelect(data.occupation_type).valid &&
      validateSalary(data.income_per_month).valid &&
      validateText(data.company_name).valid;
    return valid;
  }

  // Start checking for the #success element after the new window loads

  const applyForCC = async (e) => {
    e.preventDefault();
    if (validateFormCC()) {
      if (differentPage) {
        window.open(encodeURI(link), "_blank");
      } else {
        var initialUrl = link; // First URL to open
        var dataUrl = datalink ? datalink : ""; // Second URL to load data from

        const width = 1200;
        const height = 600;

        // Calculate the position to center the window on the screen
        const left = window.screen.width / 2 - width / 2;
        const top = window.screen.height / 2 - height / 2;

        // Window features including the calculated position
        const windowFeatures = `width=${width},height=${height},left=${left},top=${top},resizable=yes,scrollbars=yes,toolbar=no,menubar=no,status=no`;
        const newWindow = window.open(initialUrl, "_blank", windowFeatures);
        console.log("Opened..");
        if (datalink) {
          var loadInterval = setInterval(function () {
            if (newWindow && !newWindow.closed) {
              try {
                if (newWindow.location.href.match(dataUrl)) {
                  clearInterval(loadInterval);
                  console.log(
                    "Navigating to dataUrl:",
                    newWindow.location.href
                  );
                  monitorNewWindow(newWindow); // Start monitoring the window
                }
              } catch (e) {
                // Handle cross-origin access error
              }
            } else {
              clearInterval(loadInterval);
            }
          }, 100);
        } else {
          await addCC(null);
        }
      }
    }
  };

  function monitorNewWindow(win) {
    console.log("Monitoring new window...");
    var checkInterval = setInterval(function () {
      try {
        // Check if the new window has a success message element
        var successMessageElement = win.document.querySelector(successId);
        if (successMessageElement) {
          var successMessage = successMessageElement.innerText;
          console.log("Success message found:", successMessage);
          console.log(
            "Process completed successfully. Message: " + successMessage
          );
          setInterval(function () {
            win.close();
          }, 500);
          alert(successMessage);
          clearInterval(checkInterval);
        }
      } catch (e) {
        // Handle cross-origin access error
      }

      // Check if the window is closed
      if (win.closed) {
        clearInterval(checkInterval);
        console.log("The new window has been closed.");
      }
    }, 1000); // Check every second
  }

  const addCC = async (ref_id) => {
    if (!data.city) {
      await getLocData(data.pincode);
    }
    const response = await applyCC({
      data: {
        name: data.name,
        email: data.email,
        phone: data.phone,
        pan_no: data.pan_no,
        pincode: data.pincode,
        city: data.city,
        state: data.state,
        country: data.country,
        occupation_type: data.occupation_type,
        income_per_month: data.income_per_month,
        cc_type: type,
        ref_id: ref_id,
      },
    });
    setData({
      name: "",
      email: "",
      phone: "",
      pan_no: "",
      pincode: "",
      city: "",
      state: "",
      country: "",
      occupation_type: "",
      income_per_month: "",
    });

    if (response?.status === 200) {
      toast.success("Your credit card requested successfully.");
    } else {
      toast.error("Something went wrong.");
    }
  };

  function validateForm() {
    validateEmailAddr(data.email);
    validatePhoneNo(data.phone);
    validateFName(data.name);
    validatePincode(data.pincode);
    let valid =
      validateEmail(data.email).valid &&
      validateContact(data.phone).valid &&
      validatePin(data.pincode).valid &&
      validateName(data.name).valid;
    return valid;
  }

  const applyForLoan = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      if (!data.city) {
        await getLocData(data.pincode);
      }
      const response = await applyLoan({
        data: {
          loan_type: type,
          name: data.name,
          email: data.email,
          phone: data.phone,
          pincode: data.pincode,
          city: data.city,
          state: data.state,
          country: data.country,
        },
      });
      setData({
        name: "",
        email: "",
        phone: "",
        pincode: "",
      });

      if (response?.status === 200) {
        toast.success("Your loan requested successfully.");
      } else {
        toast.error("Something went wrong.");
      }
    }
  };

  function validateFormInsurance() {
    validateEmailAddr(data.email);
    validatePhoneNo(data.phone);
    validateFName(data.name);
    validatePanNo(data.pan_no);
    validateAadharNo(data.aadhar_no);
    if (type === "Life") {
      validateNominee(data.nominee);
      validateRelation(data.relation);
    }
    validateProfileFile();
    let valid =
      validateEmail(data.email).valid &&
      validateContact(data.phone).valid &&
      validatePan(data.pan_no).valid &&
      validateAadhar(data.aadhar_no).valid &&
      validateName(data.name).valid &&
      validateText(
        selectedProfileFile?.preview ? selectedProfileFile?.preview : profileDB
      ).valid &&
      type === "Life"
        ? validateText(data.nominee).valid && validateText(data.relation).valid
        : true;
    return valid;
  }

  const applyForInsurance = async (e) => {
    e.preventDefault();
    if (validateFormInsurance()) {
      const formData = new FormData();
      formData.append("type", type);
      formData.append("name", data.name);
      formData.append("email", data.email);
      formData.append("phone", data.phone);
      formData.append("pan_no", data.pan_no);
      formData.append("aadhar_no", data.aadhar_no);
      data.nominee && formData.append("nominee", data.nominee);
      data.relation && formData.append("relation", data.relation);
      selectedProfileFile.raw &&
        formData.append("photo", selectedProfileFile.raw);

      const response = await applyInsurance({
        data: formData,
      });
      setData({
        name: "",
        email: "",
        phone: "",
        pan_no: "",
        aadhar_no: "",
        nominee: "",
        relation: "",
      });
      setSelectedProfileFile({
        preview: "",
        raw: "",
      });
      setProfileDB("");

      if (response?.status === 200) {
        toast.success("Your insurance requested successfully.");
      } else {
        toast.error("Something went wrong.");
      }
    }
  };

  return (
    <>
      <div class="contact-form pb-2">
        <div class="form-group">
          <label className="label-login">Name</label>
          <input
            type="text"
            name="name"
            class="form-control"
            value={data.name}
            onBlur={() => validateFName(data.name)}
            onChange={(e) => {
              onChangeData(e);
            }}
          />
          {errors.name && (
            <div class="help-block with-errors">{errors.name}</div>
          )}
        </div>
        <div class="form-group">
          <label className="label-login">Email</label>
          <input
            type="email"
            name="email"
            class="form-control"
            value={data.email}
            onBlur={() => validateEmailAddr(data.email)}
            onChange={(e) => {
              onChangeData(e);
            }}
          />
          {errors.email && (
            <div class="help-block with-errors">{errors.email}</div>
          )}
        </div>
        <div class="form-group">
          <label className="label-login">Phone</label>
          <input
            type="text"
            name="phone"
            class="form-control"
            value={data.phone}
            onBlur={() => validatePhoneNo(data.phone)}
            onChange={(e) => {
              onChangeData(e);
            }}
          />
          {errors.phone && (
            <div class="help-block with-errors">{errors.phone}</div>
          )}
        </div>
        {applyFor === "Insurance" && (
          <>
            <div class="form-group">
              <label className="label-login">Aadhar Card No.: </label>
              <input
                type="text"
                name="aadhar_no"
                value={data.aadhar_no}
                onBlur={() => validateAadharNo(data.aadhar_no)}
                class="form-control"
                onChange={(e) => {
                  onChangeData(e);
                }}
              />
              {errors.aadhar_no && (
                <div class="help-block with-errors">{errors.aadhar_no}</div>
              )}
            </div>
            <div class="form-group">
              <label className="label-login">PAN No.: </label>
              <input
                type="text"
                name="pan_no"
                value={data.pan_no}
                onBlur={() => validatePanNo(data.pan_no)}
                class="form-control"
                onChange={(e) => {
                  onChangeData(e);
                }}
              />
              {errors.pan_no && (
                <div class="help-block with-errors">{errors.pan_no}</div>
              )}
            </div>
            <div class="form-group">
              <label className="label-login">Profile: </label>
              <div
                style={{
                  width: "150px",
                  height: "150px",
                  position: "relative",
                  overflow: "hidden",
                  borderRadius: "8px",
                  border: "1px solid #ccc",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    background: "#f9f9f9",
                    textAlign: "center",
                    lineHeight: "150px",
                  }}
                >
                  {(() => {
                    if (selectedProfileFile?.preview) {
                      return (
                        <img
                          src={selectedProfileFile.preview}
                          style={{ width: "150px", height: "150px" }}
                        />
                      );
                    } else if (profileDB) {
                      const src = process.env.REACT_APP_API + profileDB;
                      return (
                        <img
                          src={src}
                          style={{ width: "150px", height: "150px" }}
                        />
                      );
                    } else {
                      return "Upload Profile";
                    }
                  })()}
                </div>
                <input
                  type="file"
                  onChange={handleProfileUpload}
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    opacity: 0,
                    width: "100%",
                    height: "100%",
                    cursor: "pointer",
                  }}
                  accept="image/*"
                />
              </div>
              {errors.profile && (
                <div class="help-block with-errors">{errors.profile}</div>
              )}
            </div>
          </>
        )}
        {applyFor !== "Insurance" && (
          <div class="form-group">
            <label className="label-login">Area Pin Code</label>
            <input
              type="text"
              name="pincode"
              class="form-control"
              value={data.pincode}
              onBlur={() => validatePincode(data.pincode)}
              onChange={(e) => {
                onChangeData(e);
              }}
            />
            {errors.pincode && (
              <div class="help-block with-errors">{errors.pincode}</div>
            )}
          </div>
        )}
        {applyFor === "Insurance" && type === "Life" && (
          <>
            <div class="form-group">
              <label className="label-login">Nominee</label>
              <input
                type="text"
                name="nominee"
                class="form-control"
                value={data.nominee}
                onBlur={() => validateNominee(data.nominee)}
                onChange={(e) => {
                  onChangeData(e);
                }}
              />
              {errors.nominee && (
                <div class="help-block with-errors">{errors.nominee}</div>
              )}
            </div>
            <div class="form-group">
              <label className="label-login">Relation</label>
              <input
                type="text"
                name="relation"
                class="form-control"
                value={data.relation}
                onBlur={() => validateRelation(data.relation)}
                onChange={(e) => {
                  onChangeData(e);
                }}
              />
              {errors.relation && (
                <div class="help-block with-errors">{errors.relation}</div>
              )}
            </div>
          </>
        )}
        {applyFor === "Insurance" && (
          <button
            class="default-btn disabled"
            onClick={applyForInsurance}
            style={{ pointerEvents: "all", cursor: "pointer" }}
          >
            Apply Now <span></span>
          </button>
        )}
        {applyFor === "CC" && (
          <>
            <div class="form-group">
              <label className="label-login">PAN no.</label>
              <input
                type="text"
                name="pan_no"
                class="form-control"
                value={data.pan_no}
                onBlur={() => validatePanNo(data.pan_no)}
                onChange={(e) => {
                  onChangeData(e);
                }}
              />
              {errors.pan_no && (
                <div class="help-block with-errors">{errors.pan_no}</div>
              )}
            </div>
            <div class="form-group">
              <label className="label-login">Occupation Type</label>
              <br />
              <input
                type="radio"
                name="occupation_type"
                value="Salaried"
                checked={data.occupation_type === "Salaried"}
                onBlur={() => validateOccupationType(data.occupation_type)}
                onChange={(e) => {
                  onChangeData(e);
                }}
              />{" "}
              Salaried
              <input
                style={{ marginLeft: "30px" }}
                type="radio"
                name="occupation_type"
                value="Non Salaried"
                checked={data.occupation_type === "Non Salaried"}
                onBlur={() => validateOccupationType(data.occupation_type)}
                onChange={(e) => {
                  onChangeData(e);
                }}
              />{" "}
              Self Employed
              {errors.occupation_type && (
                <div class="help-block with-errors">
                  {errors.occupation_type}
                </div>
              )}
            </div>
            {data.occupation_type === "Salaried" && (
              <>
                <div class="form-group">
                  <label className="label-login">Monthly Salary: </label>
                  <input
                    type="text"
                    name="income_per_month"
                    value={data.income_per_month}
                    class="form-control w-100"
                    onBlur={() => validateIncome(data.income_per_month)}
                    onChange={(e) => {
                      onChangeData(e);
                    }}
                  />
                  {errors.income_per_month && (
                    <div class="help-block with-errors">
                      {errors.income_per_month}
                    </div>
                  )}
                </div>
                <div class="form-group">
                  <label className="label-login">Organization Name: </label>
                  <input
                    type="text"
                    name="company_name"
                    value={data.company_name}
                    class="form-control w-100"
                    onBlur={() => validateCompanyName(data.company_name)}
                    onChange={(e) => {
                      onChangeData(e);
                    }}
                  />
                  {errors.company_name && (
                    <div class="help-block with-errors">
                      {errors.company_name}
                    </div>
                  )}
                </div>
              </>
            )}
            {data.occupation_type === "Non Salaried" && (
              <>
                <div class="form-group">
                  <label className="label-login">Yearly Revenue: </label>
                  <input
                    type="text"
                    name="income_per_month"
                    value={data.income_per_month}
                    class="form-control w-100"
                    onBlur={() => validateIncome(data.income_per_month)}
                    onChange={(e) => {
                      onChangeData(e);
                    }}
                  />
                  {errors.income_per_month && (
                    <div class="help-block with-errors">
                      {errors.income_per_month}
                    </div>
                  )}
                </div>
                <div class="form-group">
                  <label className="label-login">Company Name: </label>
                  <input
                    type="text"
                    name="company_name"
                    value={data.company_name}
                    class="form-control w-100"
                    onBlur={() => validateCompanyName(data.company_name)}
                    onChange={(e) => {
                      onChangeData(e);
                    }}
                  />
                  {errors.company_name && (
                    <div class="help-block with-errors">
                      {errors.company_name}
                    </div>
                  )}
                </div>
              </>
            )}
            <button
              class="default-btn disabled"
              onClick={applyForCC}
              style={{ pointerEvents: "all", cursor: "pointer" }}
            >
              Apply Now <span></span>
            </button>
          </>
        )}
        {applyFor === "Loan" && (
          <button
            class="default-btn disabled"
            onClick={applyForLoan}
            style={{ pointerEvents: "all", cursor: "pointer" }}
          >
            Apply Now <span></span>
          </button>
        )}
      </div>

      {/* {link &&
               <>Open new windoe and get id</>
            } */}
    </>
  );
}
export default ApplyNowForm;
