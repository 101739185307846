import { Link } from "react-router-dom";
import about1 from "../Assets/img/About/img1.jpg";
import about2 from "../Assets/img/About/img2.jpg";
function About(props) {
  return (
    <div class="container">
      <div class="section-60">
        <div class="row align-items-center">
          <div class="col-lg-6 col-md-12">
            <div class="about-image">
              <img src={about1} alt="image" />
              <img src={about2} alt="image" />
            </div>
          </div>

          <div class="col-lg-6 col-md-12">
            <div className="py-3">
              <div class="section-title text-left">About Us</div>
              <div className="sub-title text-justify">
                At RV Capitals, we're all about making dreams come true! We've
                been in the business for a long time, and our reputation is
                built on trust, honesty, and doing things right. We're committed
                to giving our customers the very best.
              </div>
              <div className="sub-title text-justify pt-3">
                We have a bunch of different loans and services that are made
                just for you. They're designed to make reaching your goals as
                easy as possible. So whether you're buying a home, starting a
                business, or anything in between, we've got your back!
              </div>
              {!props.nolink && (
                <div className="about-text pt-3">
                  <Link to="/about" class="read-more-btn">
                    MORE ABOUT US <i class="fas fa-chevron-right"></i>
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default About;
