import { useEffect, useState } from "react";

import { RWebShare } from "react-web-share";

import ProfilePersonal from "../Components/profilePersonal";
import { useNavigate } from "react-router-dom";
import ApplyInstantComponent from "../Components/applyInstantComponent";

function ApplyNowInstant(props) {
  const { socket, logindata, setLogindata } = props;
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function handlePersonalDetails(status) {
    if (status === "success") {
      navigate("/apply-now/Instant/professional");
    }
  }

  return (
    <>
      <ApplyInstantComponent>
        <h3>Get Instant Loan up to ₹50,000</h3>
        <p>Apply for an Instant Loan to fulfil all your financial needs</p>
        <div class="contact-form pb-2">
          <div class="form-group mt-30">
            <ProfilePersonal
              msg="Proceed"
              onSubmit={handlePersonalDetails}
              logindata={logindata}
              setLogindata={setLogindata}
            />
          </div>
        </div>
      </ApplyInstantComponent>
    </>
  );
}
export default ApplyNowInstant;
