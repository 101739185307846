import { useEffect, useState } from "react";
import ProfilePersonal from "../Components/profilePersonal";
import ProfileIdentity from "../Components/profileIdentity";
import ProfileAddress from "../Components/profileAddress";
import ProfileProfessional from "../Components/profileProfessional";
import { getCustomer } from "../api/customer";
import { useNavigate } from "react-router-dom";
import { getLocalStorage } from "../Common/cookie";
import { toast } from "react-toastify";
import ProfileBank from "../Components/profileBank";

function Accordion({ items }) {
  const [activeIndex, setActiveIndex] = useState(0);

  const onItemClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="accordion">
      {items.map((item, index) => (
        <div className="accordion-item" key={index}>
          <div
            className={`accordion-header ${index === activeIndex && "active"}`}
            onClick={() => onItemClick(index)}
          >
            {item.header}
          </div>
          {activeIndex === index && (
            <div className="accordion-content">{item.content}</div>
          )}
        </div>
      ))}
    </div>
  );
}
function Profile({ logindata }) {
  const navigate = useNavigate();
  const [data, setData] = useState({});

  const handleSubmit = (e) => {
    toast.success("Your details saved successfully.");
  };
  const accordionItems = [
    {
      header: "Personal Details",
      content: (
        <ProfilePersonal
          msg="Save"
          onSubmit={(e) => handleSubmit(e)}
          logindata={logindata}
          profile={true}
        />
      ),
    },
    {
      header: "Identity Details",
      content: (
        <ProfileIdentity
          msg="Save"
          onSubmit={(e) => handleSubmit(e)}
          logindata={logindata}
          profile={true}
        />
      ),
    },
    {
      header: "Address Details",
      content: (
        <ProfileAddress
          msg="Save"
          onSubmit={(e) => handleSubmit(e)}
          logindata={logindata}
          profile={true}
        />
      ),
    },
    {
      header: "Bank Details",
      content: (
        <ProfileBank
          msg="Save"
          onSubmit={(e) => handleSubmit(e)}
          logindata={logindata}
          profile={true}
        />
      ),
    },
  ];

  return (
    <div className="container">
      <div className="section-60">
        <div style={{ border: "1px solid #eee", borderRadius: "2px" }}>
          <Accordion items={accordionItems} />
        </div>
      </div>
    </div>
  );
}
export default Profile;
