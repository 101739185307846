import { useEffect, useState } from "react";
import { RWebShare } from "react-web-share";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ApplyInstantComponent from "../Components/applyInstantComponent";
import ReviewLoan from "../Components/reviewLoan";
import { validateContact, validateName } from "../Common/validations";
import { getLoan, updateRef, updateStatus } from "../api/loan";
import { getLocalStorage } from "../Common/cookie";
import celebration from "../Assets/img/ApplyLoan/celebration1.gif";

import pdf from "../Assets/img/pdf.png";
import Modal from "react-bootstrap/Modal";
import logo from "../Assets/img/logoLight.png";
import approve from "../Assets/img/LoanIcon/approved.png";
function ApplyNowInstant11(props) {
  const { socket, logindata, setLogindata } = props;
  const query = new URLSearchParams(useLocation().search);
  const id = query.get("id");
  const navigate = useNavigate();

  const [data, setData] = useState({});
  const [date, setDate] = useState(null);
  const [background, setBackground] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setBackground(true);
    const timer = setTimeout(() => {
      setBackground(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const asyncFn = async () => {
      const checkCookie = getLocalStorage("rv-user");
      if (checkCookie) {
        const response = await getLoan({
          loan_id: id,
        });
        if (response?.status === 200) {
          setData(response.data);
          let currentDate = new Date();
          let currentMonth = currentDate.getMonth();
          let currentYear = currentDate.getFullYear();
          let nextMonth = currentMonth + 1;
          if (nextMonth > 11) {
            nextMonth = 0; // Set to January
            currentYear += 1;
          }
          const d = new Date(currentYear, nextMonth, 5);
          setDate(d.toDateString());
          const response1 = await updateStatus({
            token: getLocalStorage("rv-user")?.accessToken,
            data: {
              id: id,
              status: "Granted",
            },
          });
        }
      }
    };
    asyncFn();
  }, [logindata, id]);

  async function saveData(e) {
    e.preventDefault();
    // check checkbox
  }

  return (
    <>
      <div
        style={{ backgroundImage: background ? `url(${celebration}` : "unset" }}
      >
        <ApplyInstantComponent>
          <center>
            <h3>Congrats!!!</h3>
          </center>
          <p>
            Your Personal Loan of <b>Rs. {data.offered_amount}</b> is ready for
            disbursal. Amount will be credited in your bank account.
          </p>
          <div class="contact-form">
            <div class="form-group mt-3">
              <center>
                <img src={approve} width="100" alt="" />
              </center>
              <div className="card p-3 mt-3 mb-30">
                <table className="mb-0">
                  <tr>
                    <td>Loan Amount</td>
                    <td style={{ textAlign: "right" }}>
                      ₹{data.offered_amount}
                    </td>
                  </tr>
                  {data.emi_amount && (
                    <tr>
                      <td>EMI Amount</td>
                      <td style={{ textAlign: "right" }}>₹{data.emi_amount}</td>
                    </tr>
                  )}
                  {data.processing_fee && (
                    <tr>
                      <td>Fees & Charges</td>
                      <td style={{ textAlign: "right" }}>
                        ₹{data.processing_fee}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td>Application No.</td>
                    <td style={{ textAlign: "right" }}>{data.id}</td>
                  </tr>
                  <tr>
                    <td>Loan Tenure</td>
                    <td style={{ textAlign: "right" }}>
                      {data.offered_tenure} {data.offered_tenure_in}
                    </td>
                  </tr>
                  <tr>
                    <td>Interest Rate</td>
                    <td style={{ textAlign: "right" }}>
                      {data.offered_interest}%
                    </td>
                  </tr>
                  <tr>
                    <td>EMI starts from</td>
                    <td style={{ textAlign: "right" }}>{date}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </ApplyInstantComponent>
      </div>
    </>
  );
}
export default ApplyNowInstant11;
