import { useEffect, useState } from "react";
import { RWebShare } from "react-web-share";
import { useLocation, useNavigate } from "react-router-dom";
import ApplyInstantComponent from "../Components/applyInstantComponent";
import ProfileBank from "../Components/profileBank";

function ApplyNowInstant6(props) {
  const { socket, logindata, setLogindata } = props;
  const query = new URLSearchParams(useLocation().search);
  const id = query.get("id");
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function handleBankDetails(status) {
    if (status === "success") {
      navigate(`/apply-now/Instant/review?id=${id}`);
    }
  }

  return (
    <>
      <ApplyInstantComponent>
        <h3>Bank Verification</h3>
        <div class="contact-form pb-2">
          <div class="form-group mt-30">
            <ProfileBank
              msg="Proceed"
              onSubmit={handleBankDetails}
              logindata={logindata}
              socket={socket}
              loanId={id}
            />
          </div>
        </div>
      </ApplyInstantComponent>
    </>
  );
}
export default ApplyNowInstant6;
