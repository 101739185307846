import { personalDetails } from "../api/customerAuth";
import { getCustomer } from "../api/customer";
import { useEffect, useState } from "react";
import {
  validateDateOfBirth,
  validateEmail,
  validateName,
  validatePan,
  validatePin,
  validateSelect,
  validateText,
} from "../Common/validations";
import { getLocalStorage } from "../Common/cookie";
import { getLocation } from "../api/location";
import { Button, Modal } from "react-bootstrap";
import LoginForm from "../Components/login";
import logo from "../Assets/img/logoLight.png";

function ProfilePersonal(props) {
  const { msg, onSubmit, logindata, setLogindata, profile } = props;
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  const [profileDB, setProfileDB] = useState("");
  const [selectedProfileFile, setSelectedProfileFile] = useState({
    preview: "",
    raw: "",
  });
  const [isChecked, setIsChecked] = useState(false);

  function onChangeData(e) {
    if (e.target.name === "pincode") {
      if (isNaN(e.target.value)) {
        return false;
      } else {
        setLocation(e.target.value);
      }
    }
    setData((data) => ({
      ...data,
      [e.target.name]: e.target.value,
    }));
  }

  const handleProfileUpload = (event) => {
    const file = event.target.files[0];
    setSelectedProfileFile({
      preview: URL.createObjectURL(file),
      raw: file,
    });
  };

  useEffect(() => {
    const asyncFn = async () => {
      const checkCookie = getLocalStorage("rv-user");
      if (checkCookie) {
        await getCust(checkCookie.id, checkCookie.accessToken);
      }
    };
    asyncFn();
  }, [logindata]);

  const getCust = async (id, token) => {
    const response = await getCustomer({
      customer_id: id,
      token: token,
    });
    if (response?.status === 200) {
      setData(response.data);
      setProfileDB(response.data.profile);
      if (response.data.pincode) {
        await setLocation(response.data.pincode);
      }
    }
  };

  const setLocation = async (pincode) => {
    if (pincode && pincode.length === 6) {
      const location = await getLocation({
        pincode: pincode,
      });
      if (location[0]?.Status === "Success") {
        const lData = location[0].PostOffice[0];
        setData((data) => ({
          ...data,
          city: lData.District,
          state: lData.State,
          country: lData.Country,
        }));
        setErrors((errors) => ({
          ...errors,
          pincode: "",
        }));
      } else {
        setErrors((errors) => ({
          ...errors,
          pincode: "Please enter valid Pin Code",
        }));
      }
    }
  };

  function validateEmailAddr(str) {
    setErrors((errors) => ({
      ...errors,
      email: validateEmail(str).error,
    }));
  }
  function validateFName(str) {
    setErrors((errors) => ({
      ...errors,
      name: validateName(str).error,
    }));
  }
  function validateDOB(str) {
    setErrors((errors) => ({
      ...errors,
      dob: validateDateOfBirth(str).error,
    }));
  }
  function validateGender(str) {
    setErrors((errors) => ({
      ...errors,
      gender: validateSelect(str).error,
    }));
  }
  function validateMaritalStatus(str) {
    setErrors((errors) => ({
      ...errors,
      marital_status: validateSelect(str).error,
    }));
  }
  function validatePanNo(str) {
    setErrors((errors) => ({
      ...errors,
      pan_no: validatePan(str).error,
    }));
  }
  function validatePincode(str) {
    setErrors((errors) => ({
      ...errors,
      pincode: validatePin(str).error,
    }));
  }
  function validateProfileFile() {
    setErrors((errors) => ({
      ...errors,
      profile: validateText(
        selectedProfileFile?.preview ? selectedProfileFile?.preview : profileDB
      ).error,
    }));
  }
  function validateCheck() {
    if (!isChecked) {
      setErrors((errors) => ({
        ...errors,
        check: "*Please accept the terms and conditions.",
      }));
      return false;
    } else {
      setErrors((errors) => ({
        ...errors,
        check: "",
      }));
      return true;
    }
  }

  function validateForm() {
    validateEmailAddr(data.email);
    validateFName(data.name);
    validateDOB(data.dob);
    validateGender(data.gender);
    validateMaritalStatus(data.marital_status);
    if (profile) {
      validateProfileFile();
    } else {
      validateCheck();
      validatePanNo(data.pan_no);
      validatePincode(data.pincode);
    }
    let valid =
      validateEmail(data.email).valid &&
      validateName(data.name).valid &&
      validateDateOfBirth(data.dob).valid &&
      validateSelect(data.gender).valid &&
      validateSelect(data.marital_status).valid &&
      (profile
        ? validateText(
            selectedProfileFile?.preview
              ? selectedProfileFile?.preview
              : profileDB
          ).valid
        : validatePan(data.pan_no).valid && validatePin(data.pincode).valid);
    return valid;
  }

  async function saveData(e) {
    e.preventDefault();
    if (validateForm() && validateCheck()) {
      if (!errors.pincode && !errors.check) {
        const formData = new FormData();
        formData.append("id", data.id || getLocalStorage("rv-user")?.id);
        formData.append("name", data.name);
        formData.append("email", data.email);
        formData.append("dob", data.dob.split("T")[0]);
        formData.append("gender", data.gender);
        formData.append("marital_status", data.marital_status);
        formData.append("pan_no", data.pan_no);
        formData.append("pincode", data.pincode);
        formData.append("city", data.city);
        formData.append("state", data.state);
        formData.append("country", data.country);
        formData.append(
          "photo",
          selectedProfileFile.raw ? selectedProfileFile.raw : null
        );
        const response = await personalDetails({
          token: data.accessToken || getLocalStorage("rv-user")?.accessToken,
          data: formData,
        });
        if (response?.status === 200) {
          onSubmit("success");
        }
      }
    }
  }

  return (
    <>
      {profile ? (
        <div class="form-group">
          <label className="label-login">Profile: </label>
          <div
            style={{
              width: "150px",
              height: "150px",
              position: "relative",
              overflow: "hidden",
              borderRadius: "8px",
              border: "1px solid #ccc",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                background: "#f9f9f9",
                textAlign: "center",
                lineHeight: "150px",
              }}
            >
              {(() => {
                if (selectedProfileFile?.preview) {
                  return (
                    <img
                      src={selectedProfileFile.preview}
                      style={{ width: "150px", height: "150px" }}
                    />
                  );
                } else if (profileDB) {
                  const src = process.env.REACT_APP_API + profileDB;
                  return (
                    <img
                      src={src}
                      style={{ width: "150px", height: "150px" }}
                    />
                  );
                } else {
                  return "Upload Profile";
                }
              })()}
            </div>
            <input
              type="file"
              onBlur={() => validateProfileFile()}
              onChange={handleProfileUpload}
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                opacity: 0,
                width: "100%",
                height: "100%",
                cursor: "pointer",
              }}
              accept="image/*"
            />
          </div>
          {errors.profile && (
            <div class="help-block with-errors">{errors.profile}</div>
          )}
        </div>
      ) : (
        <div class="form-group">
          <label className="label-login">PAN: </label>
          <input
            type="text"
            name="pan_no"
            value={data.pan_no}
            class="form-control w-100"
            onBlur={() => validatePanNo(data.pan_no)}
            onChange={(e) => {
              onChangeData(e);
            }}
          />
          {errors.pan_no && (
            <div class="help-block with-errors">{errors.pan_no}</div>
          )}
        </div>
      )}
      <div class="form-group">
        <label className="label-login">Name: </label>
        <input
          type="text"
          name="name"
          value={data.name}
          class="form-control w-100"
          onBlur={() => validateFName(data.name)}
          onChange={(e) => {
            onChangeData(e);
          }}
        />
        {errors.name && <div class="help-block with-errors">{errors.name}</div>}
      </div>
      <div class="form-group">
        <label className="label-login">Gender: </label>
        <br />
        <input
          type="radio"
          name="gender"
          value="male"
          checked={data.gender === "male"}
          onBlur={() => validateGender(data.gender)}
          onChange={(e) => {
            onChangeData(e);
          }}
        />{" "}
        Male
        <input
          style={{ marginLeft: "30px" }}
          type="radio"
          name="gender"
          value="female"
          checked={data.gender === "female"}
          onBlur={() => validateGender(data.gender)}
          onChange={(e) => {
            onChangeData(e);
          }}
        />{" "}
        Female
        {errors.gender && (
          <div class="help-block with-errors">{errors.gender}</div>
        )}
      </div>
      <div class="form-group position-relative">
        <label className="label-login">Date of Birth: </label>
        <input
          type="date"
          name="dob"
          value={data.dob && data.dob.split("T")[0]}
          class="form-control w-100"
          onBlur={() => validateDOB(data.dob)}
          onChange={(e) => {
            onChangeData(e);
          }}
        />
        {/* <input id="dateInput" type="text" name="dob" value={data.dob && (data.dob.split('T'))[0]} class="form-control w-100" onBlur={()=>validateDOB(data.dob)} onChange={(e) => { onChangeData(e) }} /> */}
        {errors.dob && <div class="help-block with-errors">{errors.dob}</div>}
      </div>
      <div class="form-group">
        <label className="label-login">Marital Status: </label>
        <br />
        <input
          type="radio"
          name="marital_status"
          value="married"
          checked={data.marital_status === "married"}
          onBlur={() => validateMaritalStatus(data.marital_status)}
          onChange={(e) => {
            onChangeData(e);
          }}
        />{" "}
        Married
        <input
          style={{ marginLeft: "30px" }}
          type="radio"
          name="marital_status"
          value="unmarried"
          checked={data.marital_status === "unmarried"}
          onBlur={() => validateMaritalStatus(data.marital_status)}
          onChange={(e) => {
            onChangeData(e);
          }}
        />{" "}
        Unmarried
        {errors.marital_status && (
          <div class="help-block with-errors">{errors.marital_status}</div>
        )}
      </div>
      <div class="form-group">
        <label className="label-login">Email: </label>
        <input
          type="text"
          name="email"
          value={data.email}
          class="form-control w-100"
          onBlur={() => validateEmailAddr(data.email)}
          onChange={(e) => {
            onChangeData(e);
          }}
        />
        {errors.email && (
          <div class="help-block with-errors">{errors.email}</div>
        )}
      </div>
      {!profile && (
        <>
          <div class="form-group">
            <label className="label-login">Pin Code: </label>
            <input
              type="text"
              name="pincode"
              value={data.pincode}
              class="form-control w-100"
              onBlur={() => validatePincode(data.pincode)}
              onChange={(e) => {
                onChangeData(e);
              }}
            />
            {errors.pincode && (
              <div class="help-block with-errors">{errors.pincode}</div>
            )}
          </div>
          <div class="form-group">
            <label className="label-login">City: </label>
            <input
              type="text"
              name="city"
              value={data.city}
              class="form-control w-100"
              disabled
            />
          </div>
          <div class="form-group">
            <label className="label-login">State: </label>
            <input
              type="text"
              name="state"
              value={data.state}
              class="form-control w-100"
              disabled
            />
          </div>
          <div class="form-group">
            <input
              type="checkbox"
              checked={profile ? true : isChecked}
              onBlur={() => validateCheck()}
              onChange={() => setIsChecked(!isChecked)}
            />{" "}
            By continuing, I agree to the{" "}
            <a href="https://rvcapitalfinance.com/terms" target="_blank">
              <u>Terms and Conditions</u>
            </a>{" "}
            ,{" "}
            <a href="https://rvcapitalfinance.com/policy" target="_blank">
              <u>Privacy Policy</u>
            </a>{" "}
            of RV Capital Finance.
            {errors.check && (
              <div class="help-block with-errors">{errors.check}</div>
            )}
          </div>
        </>
      )}
      <button
        type="submit"
        onClick={saveData}
        class="default-btn disabled"
        style={{ pointerEvents: "all", cursor: "pointer" }}
      >
        {msg} <span></span>
      </button>
    </>
  );
}
export default ProfilePersonal;
